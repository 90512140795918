/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

h1, h2, h3 {
  border-bottom: none;
}

.split-pane-md,
.split-pane-ios {
    --side-max-width: 300px;
}

.alert-kem {
  .alert-wrapper {
    .alert-head {
      // header styling here eg. background
      background: var(--ion-color-primary);
      h2 {
      // header text styling here eg. color, font size
      color: var(--smoke-bg);
      padding-bottom: 12px;
      }
    }

    .alert-message {
      font-size: large;
      color: black;
      padding-top: 17px;
    }
  }
}

.mat-divider {
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: lightgrey;
}

ion-item {
  cursor: pointer;
  --highlight-height: 2px;
  --highlight-color-focused: #0a11e8;
  --highlight-color-valid: #30e319;
  --highlight-color-invalid: #e30c3a;
}

.center-me {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
}

.grid-66 {
  width: calc(66.6666% - 15px);
  min-width: 350px;
  max-width: 600px;
}

// ion-title {
//   padding-left: 17px;
// }

.ion-invalid.ion-touched ion-label {
  color: var(--ion-color-danger);
}

ion-toast.custom-toast {
  --background: #222222;
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: #ffffff;
}

// ion-toast.custom-toast::part(message) {
//   font-style: italic;
// }

ion-toast.custom-toast::part(button) {
  border-left: 1px solid #d2d2d2;
  color: --ion-primary-color;
  font-size: 15px;
}

@media (any-hover: hover) {
  :host(.button-solid.ion-color:hover) .button-native::after {
    --background: transparent !important;
    opacity: 0.00;
  }

  :host(.button-clear.ion-color:hover) .button-native::after,
  :host(.button-outline.ion-color:hover) .button-native::after {
    --background: transparent !important;
    opacity: 0.00;
  }
}
